@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css?family=Inter);
@layer components {
    h1,
    h3,
    div,
    p,
    span,
    label {
        font-family: Inter, sans-serif;
    }
    h1 {
        @apply text-4xl mb-6;
    }
    h3 {
        @apply text-lg font-semibold py-2;
    }
    div,
    p,
    label {
        @apply text-base;
    }
    label {
        @apply block my-2;
    }
    .border-1 {
        border-width: 1px;
    }
    /* inputs */
    input {
        @apply rounded-md border-1 border-fg-primary w-full my-2 p-2;
    }
    input:focus {
        @apply outline-none;
    }
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button,
    input[type='number'] {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield !important;
    }
    /* end inputs */

    /* bank id */
    .bankId div {
        @apply my-4;
    }
    .bankId .qr-code {
        @apply flex justify-start h-full w-full;
    }
    .bankId .title {
        @apply text-lg text-fg-primary;
    }
    .bankId .description {
        @apply text-base text-muted;
    }
    .bankId button {
        @apply bg-fg-primary border-fg-primary text-white border-2 p-2 hover:bg-fg-primary-400 hover:border-fg-primary-400 rounded-full place-self-center;
    }
    /* end bank id */

    .scroller::-webkit-scrollbar {
        @apply w-2 h-2
    }

    /* Track */
    .scroller::-webkit-scrollbar-track {
        @apply bg-fg-primary-100;
    }

    /* Handle */
    .scroller::-webkit-scrollbar-thumb {
        @apply bg-fg-primary w-4 h-4 rounded-t-2xl;
    }

    /* Handle on hover */
    .scroller::-webkit-scrollbar-thumb:hover {
        @apply bg-fg-primary-400;
    }
}
